import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './NewsArticle.css';


const NewsArticle = () => {
  const { slug } = useParams();

  const [htmlContent, setHtmlContent] = useState('');
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  // 從 GCS 獲取 HTML 內容
  useEffect(() => {
    const fetchHtmlContent = async () => {
      let news_id = null;
      try {
        
        const response = await axios.get(`https://price-backend-765051711628.asia-east1.run.app/news/${slug}`);
        // const response = await axios.get(`http://localhost:8080/news/${slug}`);
        news_id = response.data.news.news_id;

        setArticles(response.data.related_news);

        const htmlResponse = await axios.get(`https://storage.googleapis.com/www.usdtprice.com/public/html/${news_id}`);
        setHtmlContent(htmlResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('無法取得新聞 ID 或載入 HTML 內容:', error);
      }
    };
    
    fetchHtmlContent();
  }, [slug]);

  // 從後端 API 獲取最新文章
  // useEffect(() => {
  //   const fetchArticles = async () => {
  //     try {
  //       // const response = await axios.get(`https://price-backend-765051711628.asia-east1.run.app/news/related/${slug}`);
  //       const response = await axios.get(`http://localhost:8080/news/related/${slug}`); // TODO:

  //       setArticles(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('無法載入最新文章:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchArticles();
  // }, [slug]);

  if (loading) {
    return <div>載入中...</div>;
  }

  return (
    <div className="news-container">
      <div className="main-content"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />

      
      {/* 最新文章列表 */}  
      <div className="sidebar">
        <h2>加密貨幣文章</h2>
        <ul>
          {articles.map((article, index) => (
            <li key={article.article_id || article.Slug || index}>
              <a href={`/news/${article.slug}`}>
                {article.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NewsArticle;