import React, { useState } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ExchangeRates from '../components/ExchangeRates';
import LoginPage from '../components/admin/LoginPage';
import NewsEditPage from '../components/admin/NewsEditPage';
import NewsHome from '../components/news/NewsHome';
import NewsArticle from '../components/news/NewsArticle';
import Navigation from '../components/Navigation';

function RequireAuth({ children, isAuthenticated, setAuth }) {
  if (!isAuthenticated) {
    return <LoginPage setAuth={setAuth} />;
  }
  return children;
}

export const AppRouter = () => {
  const [isAuthenticated, setAuth] = useState(false);

  return createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <Navigation />
          <ExchangeRates />
        </>
      ),
    },
    {
      path: '/admin/login',
      element: <LoginPage setAuth={setAuth} />,
    },
    {
      path: '/admin/news/edit',
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <NewsEditPage />
        </RequireAuth>
      ),
    },
    {
      path: '/news',
      element: (
        <>
          <Navigation />
          <NewsHome />
        </>
      ),
    },
    {
      path: '/news/:slug',
      element: (
        <>
          <Navigation />
          <NewsArticle />
        </>
      ),
    }
  ]);
};