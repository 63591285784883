import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="usdt-nav-wrapper">
      <nav className="usdt-navbar">
        <div className="usdt-nav-left">
          <div className="usdt-coin-container">
            <Link to="/" className="usdt-coin-container-link">
              <img src="/assets/usdt-logo.png" alt="USDT Logo" className="usdt-coin-logo" />
              <span className="usdt-coin-title">USDT比價網</span>
            </Link>
          </div>
          <div className="usdt-news-container">
            <Link to="/news" className="usdt-news-link">
            加密貨幣資訊分享
            </Link>
          </div>
        </div>
        
        <div className="usdt-nav-right">
          <button 
            className={`usdt-hamburger-menu ${isMenuOpen ? 'active' : ''}`}
            aria-label="選單"
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </nav>

      <div className={`usdt-mobile-menu ${isMenuOpen ? 'active' : ''}`}>
        <Link to="/" className="usdt-mobile-link">USDT 比價網</Link>
        <Link to="/news" className="usdt-mobile-link">加密貨幣資訊分享</Link>
      </div>
    </div>
  );
};

export default Navigation;