import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { AppRouter } from './routes';
// import ReactGA from 'react-ga';

// // 初始化 GA
// ReactGA.initialize('G-5M4T1SW6PS');

function App() {
  return <RouterProvider router={AppRouter()} />;
}

export default App;