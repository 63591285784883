import React, { useState, useEffect, useRef, useCallback } from 'react';
import Quill from 'quill';
// import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill/dist/quill.snow.css';
// import 'quill-color-picker-enhance/dist/index.css';
import './NewsEditPage.css';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import QuillTableBetter from 'quill-table-better';
import 'quill-table-better/dist/quill-table-better.css';
import { Helmet } from 'react-helmet';

const API_BASE_URL = 'https://price-backend-765051711628.asia-east1.run.app';

// const API_BASE_URL = 'http://127.0.0.1:8080';

// 註冊增強的 Snow 主題
// Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);
// 註冊 quill-table-better 模組
Quill.register({
  'modules/table-better': QuillTableBetter
}, true);

function NewsEditPage() {
  const [title, setTitle] = useState('');
  const [excerpt, setExcerpt] = useState(''); // 新增摘要狀態
  const [content, setContent] = useState('');
  const [images, setImages] = useState({});
  const [seoDescription, setSeoDescription] = useState(''); // 新增 SEO 描述狀態
  const [seoKeywords, setSeoKeywords] = useState(''); // 新增 SEO 關鍵字狀態
  const [slug, setSlug] = useState(''); // 新增 slug 狀態
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 2000,
      useWebWorker: true
    };
    
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('圖片壓縮失敗:', error);
      throw error;
    }
  };
  
  const handleImageUpload = useCallback(async (file) => {
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      
      return new Promise((resolve) => {
        reader.onload = (event) => {
          const imageId = uuidv4();
          const imageKey = `{{image:${imageId}}}`;
          const imageDataUrl = event.target.result;
          setImages(prevImages => ({
            ...prevImages,
            [imageKey]: imageDataUrl
          }));
          resolve({ imageKey, imageDataUrl });
        };
      });
    } catch (error) {
      console.error('圖片處理失敗:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['blockquote', 'code-block'],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
            ['clean'],
            ['table-better']
          ],
          table: false,
          'table-better': {
            toolbarTable: true,
            language: 'en_US',
            menus: ['column', 'row', 'merge', 'table', 'cell', 'wrap']
          }
        },
        // formats: ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'blockquote', 'code-block', 'link', 'image', 'video', 'table-better'],
        placeholder: '在此輸入文章內容...',
      });

      quillRef.current.on('text-change', () => {
        setContent(quillRef.current.root.innerHTML);
      });

      // 確保 toolbar 存在後再添加 handler
      const toolbar = quillRef.current.getModule('toolbar');
      if (toolbar) {
        toolbar.addHandler('image', () => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();

          input.onchange = async () => {
            const file = input.files[0];
            console.log('圖片上傳處理開始');
            if (file) {
              try {
                const { imageKey, imageDataUrl } = await handleImageUpload(file);
                const range = quillRef.current.getSelection(true);
                
                quillRef.current.insertEmbed(range.index, 'image', imageDataUrl);
                quillRef.current.setSelection(range.index + 1);

                const imageElement = quillRef.current.root.querySelector(`img[src="${imageDataUrl}"]`);
                if (imageElement) {
                  imageElement.setAttribute('data-image-key', imageKey);
                }
              } catch (error) {
                console.error('圖片上傳失敗:', error);
              }
            }
          };
        });
      }

      // 移除 Quill 的 clipboard.addMatcher
      // 添加 document 的 paste 事件監聽器
      document.addEventListener('paste', async (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            const file = items[i].getAsFile();
            if (file) {
              try {
                const { imageKey, imageDataUrl } = await handleImageUpload(file);
                
                const imageElement = document.createElement('img');
                imageElement.src = imageDataUrl;
                imageElement.setAttribute('data-image-key', imageKey);
                console.log('圖片粘貼處理成功', imageKey);
              } catch (error) {
                console.error('圖片粘貼處理失敗:', error);
              }
            }
          }

          if (items[i].type === 'text/html') {
            items[i].getAsString((html) => {
              // 使用 DOMParser 解析 HTML 字符串
              const parser = new DOMParser();
              const doc = parser.parseFromString(html, 'text/html');
              
              // 查找所有的 <img> 標籤
              const imgElements = doc.querySelectorAll('img');
              imgElements.forEach(async (imgElement) => {
                const imageUrl = imgElement.src;
                if (imageUrl) {
                  try {
                    // 下載圖片並進行處理
                    const response = await fetch(imageUrl);
                    const blob = await response.blob();
                    const file = new File([blob], 'image.png', { type: blob.type });
                    const { imageKey, imageDataUrl } = await handleImageUpload(file);
                    const imageElement = document.createElement('img');
                    imageElement.src = imageDataUrl;
                    imageElement.setAttribute('data-image-key', imageKey);
      
                    console.log('圖片插入成功', imageKey);
                  } catch (error) {
                    console.error('圖片處理失敗:', error);
                  }
                }
              });
            });
          }
        }
      });
    }
  }, [handleImageUpload]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleExcerptChange = (e) => { 
    setExcerpt(e.target.value);
  };

  const handleSeoDescriptionChange = (e) => {
    setSeoDescription(e.target.value);
  };

  const handleSeoKeywordsChange = (e) => {
    setSeoKeywords(e.target.value);
  };

  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };

  const publishArticle = async (publish = true) => {
    const currentDate = new Date().toISOString().split('T')[0];
    let htmlContent = `
      <!DOCTYPE html>
      <html lang="zh-TW">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta name="description" content="${seoDescription}">
          <meta name="keywords" content="${seoKeywords}">

          <!-- Facebook Meta Tags -->
          <meta property="og:url" content="REPLACE_WITH_WEBSITE_URL">
          <meta property="og:type" content="website">
          <meta property="og:title" content="${title}">
          <meta property="og:description" content="${seoDescription}">
          <meta property="og:image" content="REPLACE_WITH_IMAGE_URL">

          <!-- Twitter Meta Tags -->
          <meta name="twitter:card" content="summary_large_image">
          <meta property="twitter:domain" content="usdtprice.com">
          <meta property="twitter:url" content="REPLACE_WITH_WEBSITE_URL">
          <meta name="twitter:title" content="${title}">
          <meta property="twitter:description" content="${seoDescription}">
          <meta name="twitter:image" content="REPLACE_WITH_IMAGE_URL">
          <title>${title}</title>
      </head>
      <body>
          <h1 class="article-title">${title}</h1>
          <div class="publish-date">${currentDate}</div>
          <div class="excerpt">${excerpt}</div> 
          <div class="content">
            ${content}
          </div>
      </body>
      </html>
    `;

    // 替換 HTML 中的圖片 URL 為 imageKey
    const processedHtml = htmlContent.replace(/<img[^>]+>/g, (match) => {
      const imageKeyMatch = match.match(/data-image-key="([^"]+)"/);
      if (imageKeyMatch) {
        console.log('imageKeyMatch 有中');
        return `<img src="${imageKeyMatch[1]}" />`;
      } else {
        console.log('imageKeyMatch 沒中');
      }
      return match;
    });

    // TODO: 產生 news_id
    const payload = {
      title,
      excerpt,
      html: processedHtml,
      images,
      slug,
      publish
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/articles/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('文章發布失敗');
      }

      if (publish) {
        alert('文章發布成功！');
      } else {
        alert('文章保存成功！');
      }
    } catch (error) {
      console.error('文章發布失敗:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>USDT比價網 | 新聞編輯</title>
      </Helmet>
    <div className="news-edit-body">
      <div className="article-edit-page">
        <h1 className="page-title">編輯文章</h1>
        <div className="editor-container">
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="title-input"
            placeholder="請輸入文章標題"
          />
          <textarea
            value={excerpt}
            onChange={handleExcerptChange}
            className="excerpt-input"
            placeholder="請輸入文章摘要"
          />
          <textarea
            value={seoDescription}
            onChange={handleSeoDescriptionChange}
            className="seo-description-input"
            placeholder="請輸入SEO描述"
          />
          <textarea
            value={seoKeywords}
            onChange={handleSeoKeywordsChange}
            className="seo-keywords-input"
            placeholder="請輸入SEO關鍵字，請用 ,隔開"
          />
          <input
            type="text"
            value={slug}
            onChange={handleSlugChange}
            className="slug-input"
            placeholder="請輸入新聞網址結尾，例如 crypto-signup-comparison"
          />
          <div ref={editorRef} className="content-editor custom-quill"></div>
          <div className="button-group">
            <button type="button" className="editor-button submit-button" onClick={() => publishArticle(false)}>保存文章</button>
            <button type="button" className="editor-button publish-button" onClick={() => publishArticle(true)}>發布文章</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default NewsEditPage;
