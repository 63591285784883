import { useEffect, useState } from 'react';
import './NewsHome.css';
import { Helmet } from 'react-helmet';

/**
 * @typedef {Object} Article
 * @property {string} URL
 * @property {string} Cover
 * @property {string} Title
 * @property {string} Excerpt
 * @property {string} PublishedAt
 */

const NewsHome = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        console.log('fetching articles');
        const response = await fetch('https://price-backend-765051711628.asia-east1.run.app/news'); // TODO:
        // const response = await fetch('http://localhost:8080/news');
        const data = await response.json();
        const formattedArticles = data.map(article => ({
          URL: article.URL,
          Cover: article.cover,
          Title: article.title,
          Excerpt: article.excerpt,
          PublishedAt: article.published_at
        }));
        setArticles(formattedArticles);
        console.log('articles fetched:', formattedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <>
      <Helmet>
        <title>USDT比價網 | 加密貨幣資訊分享</title>
        <meta name="description" content="即時掌握區塊鏈與加密貨幣交易所的最新動態與深度分析，為投資者提供最前沿的市場新聞" />
        <meta property="og:url" content="https://www.usdtprice.com/news" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="USDT比價網 | 加密貨幣資訊分享" />
        <meta property="og:description" content="即時掌握區塊鏈與加密貨幣交易所的最新動態與深度分析，為投資者提供最前沿的市場新聞" />
        <meta property="og:image" content="https://cryptologos.cc/logos/tether-usdt-logo.png" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="usdtprice.com" />
        <meta property="twitter:url" content="https://www.usdtprice.com/news" />
        <meta name="twitter:title" content="USDT比價網 | 加密貨幣資訊分享" />
        <meta property="twitter:description" content="即時掌握區塊鏈與加密貨幣交易所的最新動態與深度分析，為投資者提供最前沿的市場新聞" />
        <meta name="twitter:image" content="https://cryptologos.cc/logos/tether-usdt-logo.png" />

      </Helmet>

    <div className="news-home-app-wrapper">
    <div className="news-home">
      <div className="news-home__container">
        <h1 className="news-home__title">加密貨幣文章</h1>
        <div className="news-home__article-grid">
          {articles.map((article, index) => (
            <a key={index} href={article.URL} className="news-home__article-card">
              <div className="news-home__article-images">
                <img 
                  src={article.Cover} 
                  alt={article.Title} 
                  className="news-home__article-main-image" 
                />
              </div>
              <div className="news-home__article-content">
                <h2 className="news-home__article-title">{article.Title}</h2>
                <p className="news-home__article-excerpt">{article.Excerpt}</p>
                <p className="news-home__article-date">{article.PublishedAt}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default NewsHome;